/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}

.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
}

.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: none;
  padding: 0 !important;
  font: inherit;
}

.owl-carousel.owl-loaded {
  display: block;
}

.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}

.owl-carousel.owl-hidden {
  opacity: 0;
}

.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}

.owl-carousel.owl-drag .owl-item {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}

.owl-carousel.owl-rtl {
  direction: rtl;
}

.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.owl-carousel .owl-animated-in {
  z-index: 0;
}

.owl-carousel .owl-animated-out {
  z-index: 1;
}

.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}

.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}

.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("../../node_modules/owl.carousel/dist/assets/owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
  transform: scale(1.3, 1.3);
}

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/**
 * Owl Carousel v2.2.0
 * Copyright 2013-2016 David Deutsch
 * Licensed under MIT (https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE)
 */
/*
 * 	Default theme - Owl Carousel CSS File
 */
body .owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
body .owl-theme .owl-nav [class*=owl-] {
  color: #FFF;
  font-size: 14px;
  margin: 5px;
  padding: 4px 7px;
  background: #D6D6D6;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
body .owl-theme .owl-nav [class*=owl-]:hover {
  background: #ff8400;
  color: #FFF;
  text-decoration: none;
}
body .owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
body .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px;
}
body .owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
body .owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
body .owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px 7px;
  background: #D6D6D6;
  display: block;
  -webkit-backface-visibility: visible;
  transition: opacity 200ms ease;
  border-radius: 30px;
}
body .owl-theme .owl-dots .owl-dot span:after {
  border-radius: 30px;
}
body .owl-theme .owl-dots .owl-dot.active span, body .owl-theme .owl-dots .owl-dot:hover span {
  background: #ff8400;
}

/*!
Template :	AppsLand - App Landing Template
Author	 :	Softnio
Version	 :	1.3.1
Updated	 :	10.22.2018
*/
/**
01. RESET CSS
02. Fonts CSS
03. Common CSS
04. Navigation
05. Header Section
06. Features Box Section
07. Features Section
08. Screenshots Section
09. Statistics Section
10. Pricing Section
11. Faq Section
12. Team Section
13. Testimonial Section
14. Contact Section
15. Footer Section
16. Fix Media Query
17. Flat Page
**/
/*!
 * IMPORTANT NOTE: DO NOT Edit this file.
 * Best to write own code in css/theme.css file.
 **/
/* 01. RESET CSS */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, ins, kbd, q, s, samp,
small, strike, strong, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  vertical-align: baseline;
  background: transparent;
}

img {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

:focus {
  outline: 0;
}

a:focus {
  outline: 0;
  text-decoration: none;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  text-decoration: none;
  color: #ff8400;
}

a:hover {
  text-decoration: none;
  color: #ff6100;
}

::-webkit-input-placeholder {
  opacity: 1;
}

:-moz-placeholder {
  opacity: 1;
}

::-moz-placeholder {
  opacity: 1;
}

:-ms-input-placeholder {
  opacity: 1;
}

img {
  max-width: 100%;
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.7;
  letter-spacing: 0.01em;
  font-weight: 300;
  color: #333;
}

.font-primary, p.lead {
  font-family: "Open Sans", sans-serif;
}

.font-secondary {
  font-family: "Quicksand", sans-serif;
}

p, ul, ol, blockquote, table {
  margin-bottom: 18px;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "Quicksand", sans-serif;
  line-height: 1.25;
  font-weight: 700;
  margin-bottom: 28px;
  color: #666;
}

p:last-child, ul:last-child, ol:last-child, blockquote:last-child, table:last-child, h1:last-child, .h1:last-child, h2:last-child, .h2:last-child, h3:last-child, .h3:last-child, h4:last-child, .h4:last-child, h5:last-child, .h5:last-child, h6:last-child, .h6:last-child {
  margin-bottom: 0;
}

h1, .h1 {
  font-size: 3.125em;
  line-height: 1.35;
}

h2, .h2 {
  font-size: 2.5em;
}

h3, .h3 {
  font-size: 1.375em;
}

h4, .h4 {
  font-size: 1.125em;
}

h5, .h5 {
  font-size: 1.875em;
}

h6, .h6 {
  font-size: 1.265em;
}

p.lead {
  font-size: 1.375em;
  line-height: 1.7;
}

blockquote {
  font-size: 0.9375em;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  body {
    font-size: 14px;
  }

  h1, .h1 {
    font-size: 2em;
  }

  h2, .h2 {
    font-size: 1.7em;
  }

  p {
    font-weight: 400;
  }
}
/* 03. Common CSS */
html {
  height: 100%;
}

body {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.no-padding {
  padding: 0;
}

.no-margin {
  margin: 0;
}

.gutter-10 {
  padding-left: 10px;
  padding-right: 10px;
}

.fix-gutter-10 {
  margin-left: -10px;
  margin-right: -10px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pt-10 {
  padding-top: 10px;
}

.pb-20 {
  padding-bottom: 20px;
}

.pt-20 {
  padding-top: 20px;
}

.pb-30 {
  padding-bottom: 30px;
}

.pt-30 {
  padding-top: 30px;
}

.pb-40 {
  padding-bottom: 40px;
}

.pt-40 {
  padding-top: 40px;
}

.pb-50 {
  padding-bottom: 50px;
}

.pt-50 {
  padding-top: 50px;
}

.pb-60 {
  padding-bottom: 60px;
}

.pt-60 {
  padding-top: 60px;
}

.pb-80 {
  padding-bottom: 80px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pt-80 {
  padding-bottom: 80px;
}

.pb-90 {
  padding-bottom: 90px;
}

.pt-90 {
  padding-top: 90px;
}

.pb-100 {
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pt-120 {
  padding-top: 120px;
}

.ptb-120 {
  padding: 120px 0;
}

.form-group {
  margin-bottom: 10px;
}

.form-control {
  height: 50px;
  padding: 13px 15px;
  color: #666;
  background: #fff;
  border: 1px solid #eee;
  border-radius: 8px;
  box-shadow: none;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.form-control.error {
  border: 1px solid #f00;
}

.txtarea.form-control {
  height: 85px;
}

.form-control:focus {
  border-color: #cecece;
  box-shadow: none;
}

label.error {
  display: none !important;
}

.section {
  position: relative;
}

a.anchor {
  display: block;
  position: relative;
  top: -80px;
  visibility: hidden;
}

.relative {
  position: relative;
}

.white-bg {
  background: #fff;
}

.button {
  font-size: 1em;
  font-weight: 700;
  line-height: 3.125;
  border: none;
  border-radius: 25px;
  padding: 0 40px;
  display: inline-block;
  color: #fff;
  text-align: center;
  background: #ff6100;
}

.button-border {
  line-height: 3;
  border: 1px solid #fff;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.text-area {
  width: 100%;
  height: 150px;
  border-radius: 15px;
  padding: 10px 15px;
  margin-bottom: 5px;
  border: 1px solid #404040;
  resize: none;
}

/* @v120 -s */
.theme-pro .button-border {
  border: 1px solid #333;
}

.theme-pro .button.button-border {
  color: #333;
}

/* @v120 -e */
.button-uppercase {
  text-transform: uppercase;
}

.button-transparent {
  background: transparent;
}

.button:focus,
.button:hover {
  color: #fff;
  background: #d85200;
}

.button-border:focus,
.button-border:hover {
  background: white;
  color: #fb9e25;
}

.buttons {
  margin: 0 -10px;
}

.buttons li {
  display: inline-block;
  margin: 0 10px 20px;
}

.download-buttons {
  padding-top: 50px;
  margin: 0 -5px;
}

.download-buttons li {
  display: inline-block;
  margin: 0 5px 10px;
}

.download-buttons img {
  max-height: 90px;
  width: auto !important;
}

.heading span {
  color: #ff8400;
}

.heading-light {
  color: #fff;
}

/* @v120 -s */
.theme-pro .heading-light {
  color: #333;
}

.theme-pro .has-bg-image .heading-light {
  color: #fff;
}

/* @v120 -e */
.section-head {
  padding: 80px 0 47px;
}

.section-head-about {
  padding: 50px 0 80px;
}

.side-heading {
  margin-bottom: 50px;
}

.side-heading a {
  color: #fff;
  border-bottom: 1px solid #fff;
}

.side-heading a:hover {
  border-color: transparent;
}

.bg-gradiant.mfp-bg,
.gradiant-background {
  background-color: #35096b;
  background-image: linear-gradient(225deg, rgba(255, 132, 0, 0), #ff8400), linear-gradient(45deg, rgba(247, 191, 19, 0), #f7bf13), linear-gradient(135deg, #f5c21b, #f7e5a9);
}

/* @v120 -s */
.theme-pro .section.gradiant-background {
  background: #ecf4fd;
}

.theme-pro .has-bg-image > .gradiant-background,
.theme-pro .has-bg-video > .gradiant-background {
  background: #ecf4fd;
}

/* @v120 -e */
.gradiant-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.45;
}

.gradiant-light {
  opacity: 0.25;
}

.header-section .gradiant-overlay {
  opacity: 0.65;
}

.grey-background {
  background: #fbfbfb;
}

.box {
  padding: 30px 0 24px;
  border-radius: 8px;
  background: #fff;
  margin-bottom: 10px;
  -moz-box-shadow: 0 0 15px 0 rgba(2, 3, 3, 0.1);
  -webkit-box-shadow: 0 0 15px 0 rgba(2, 3, 3, 0.1);
  box-shadow: 0 0 15px 0 rgba(2, 3, 3, 0.1);
}

.box-small {
  padding: 20px 0 14px;
}

.box h4 {
  padding-top: 15px;
  text-transform: uppercase;
}

.box h5 {
  text-transform: uppercase;
  color: #333;
  padding-top: 12px;
}

.box h5 span {
  display: block;
  font-size: 0.53em;
  letter-spacing: 0.02em;
  color: #666;
  padding-top: 4px;
}

.box-icon {
  height: 70px;
  width: 70px;
  margin: 0 auto;
  border-radius: 50%;
  background-color: #35096b;
  background-image: linear-gradient(225deg, rgba(255, 132, 0, 0), #ff8400), linear-gradient(45deg, rgba(247, 191, 19, 0), #f7bf13), linear-gradient(135deg, #f5c21b, #f7e5a9);
}

.box-icon .fa,
.box-icon .ti {
  width: 66px;
  line-height: 66px;
  border-radius: 50%;
  margin-top: 2px;
  font-size: 2.25em;
  color: #ff8400;
  background: #fff;
  display: inline-block;
}

.box-icon-small .fa,
.box-icon-small .ti {
  font-size: 1.2em;
}

.txt-entry {
  margin-bottom: 30px;
}

.txt-entry h3 {
  margin-bottom: 22px;
}

.txt-quote {
  border-top: 1px solid #ececec;
  margin-top: 30px;
}

.txt-quote blockquote {
  padding: 30px 0 0;
  font-style: italic;
  font-weight: 500;
  opacity: 0.8;
}

.txt-quote .quote-title {
  font-weight: 700;
}

.video {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
}

.video-container {
  border-radius: 8px;
}

.video img {
  width: 100%;
  border-radius: 8px;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0.8;
  border-radius: 8px;
}

.video-play {
  position: absolute;
  top: 50%;
  left: 50%;
  line-height: 80px;
  width: 80px;
  margin-top: -40px;
  margin-left: -40px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  color: #ff6100;
  background: rgba(246, 246, 246, 0.75);
  border-radius: 50%;
  text-align: center;
  font-size: 2.25em;
}

.video-play:hover,
.video-play:focus {
  color: #ff6100;
  background: #fff;
}

.video-play i {
  padding-left: 8px;
}

.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  margin: 5px;
  background: #d0d0d0;
  border-radius: 0;
  position: relative;
}

.owl-theme .owl-dots .owl-dot span:after {
  position: absolute;
  border: 1px solid #ed355b;
  top: -2px;
  left: -2px;
  content: "";
  height: 14px;
  width: 14px;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
  opacity: 0;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #ed355b;
}

.owl-theme .owl-dots .owl-dot.active span:after,
.owl-theme .owl-dots .owl-dot:hover span:after {
  opacity: 1;
}

.owl-theme .owl-dots {
  position: relative;
  top: 40px;
  z-index: 99;
}

.owl-theme .owl-nav [class*=owl-] {
  background: #fff;
  color: #ff8400;
  margin: 5px 40px;
}

.owl-theme .owl-nav [class*=owl-]:hover {
  background: #fff;
  color: #ff8400;
}

.owl-nav .owl-prev,
.owl-nav .owl-next {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  border-radius: 50%;
  height: 44px;
  width: 44px;
  text-align: center;
  opacity: 1;
  font-size: 20px;
  z-index: 999;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.owl-nav .owl-prev .fa,
.owl-nav .owl-next .fa {
  font-size: 1.5em;
  line-height: 1.7;
}

.owl-nav .owl-prev {
  left: 0;
}

.owl-nav .owl-next {
  right: 0;
}

.owl-nav .owl-prev:hover {
  left: -10px;
}

.owl-nav .owl-next:hover {
  right: -10px;
}

.slide-screen.owl-theme .owl-dots {
  top: 40px;
}

.imagebg {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  z-index: 0;
  opacity: 0;
  transition: opacity 0.4s;
}

.fixed-bg .imagebg {
  background-attachment: fixed;
}

.imagebg img {
  display: none;
}

.image-on-left .imagebg,
.image-on-right .imagebg {
  width: 50%;
}

.image-on-left .imagebg {
  right: 50%;
}

.image-on-right .imagebg {
  left: 50%;
}

.image-slice .imagebg {
  width: 40%;
  left: 0;
}

.image-slice .imagebg + .imagebg {
  width: 60%;
  left: 40%;
}

.bg-image-loaded {
  opacity: 1;
}

.animate-top,
.animate-bottom,
.animate-left,
.animate-right,
.animate-scale-out,
.animate-scale-in {
  position: relative;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.delay1ms {
  -webkit-transition-delay: 0.1s;
  -moz-transition-delay: 0.1s;
  transition-delay: 0.1s;
}

.delay2ms {
  -webkit-transition-delay: 0.2s;
  -moz-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.delay3ms {
  -webkit-transition-delay: 0.3s;
  -moz-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.delay4ms {
  -webkit-transition-delay: 0.4s;
  -moz-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.delay5ms {
  -webkit-transition-delay: 0.5s;
  -moz-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.delay6ms {
  -webkit-transition-delay: 0.6s;
  -moz-transition-delay: 0.6s;
  transition-delay: 0.6s;
}

.delay7ms {
  -webkit-transition-delay: 0.7s;
  -moz-transition-delay: 0.7s;
  transition-delay: 0.7s;
}

.delay8ms {
  -webkit-transition-delay: 0.8s;
  -moz-transition-delay: 0.8s;
  transition-delay: 0.8s;
}

.delay9ms {
  -webkit-transition-delay: 0.9s;
  -moz-transition-delay: 0.9s;
  transition-delay: 0.9s;
}

.delay10ms {
  -webkit-transition-delay: 1s;
  -moz-transition-delay: 1s;
  transition-delay: 1s;
}

.delay11ms {
  -webkit-transition-delay: 1.1s;
  -moz-transition-delay: 1.1s;
  transition-delay: 1.1s;
}

.delay12ms {
  -webkit-transition-delay: 1.2s;
  -moz-transition-delay: 1.2s;
  transition-delay: 1.2s;
}

.delay13ms {
  -webkit-transition-delay: 1.3s;
  -moz-transition-delay: 1.3s;
  transition-delay: 1.3s;
}

.delay14ms {
  -webkit-transition-delay: 1.4s;
  -moz-transition-delay: 1.4s;
  transition-delay: 1.4s;
}

.delay15ms {
  -webkit-transition-delay: 1.5s;
  -moz-transition-delay: 1.5s;
  transition-delay: 1.5s;
}

.animate-top {
  top: -30px;
}

.animate-top.animate-long {
  top: -120px;
}

.animate-bottom {
  bottom: -30px;
}

.animate-bottom.animate-long {
  bottom: -120px;
}

.animate-left {
  left: -30px;
}

.animate-left.animate-long {
  left: -120px;
}

.animate-right {
  right: -30px;
}

.animate-right.animate-long {
  right: -120px;
}

.animate-scale-out {
  transform: scale(0.8);
}

.animate-scale-in {
  transform: scale(1.3);
}

.active .animate-top,
.active .animate-bottom,
.active .animate-left,
.active .animate-right,
.active .animate-scale-out,
.active .animate-scale-in {
  opacity: 1;
}

.active .animate-top {
  top: 0;
}

.active .animate-bottom {
  bottom: 0;
}

.active .animate-left {
  left: 0;
}

.active .animate-right {
  right: 0;
}

.active .animate-scale-out {
  transform: scale(1);
}

.active .animate-scale-in {
  transform: scale(1);
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  z-index: 99999999;
}

#status {
  width: 100px;
  height: 100px;
  position: absolute;
  left: 50%;
  top: 50%;
  background-image: url(/images/landing/loading.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 38px auto;
  margin: -50px 0 0 -50px;
}

.panel-group .panel {
  border-radius: 0;
  box-shadow: none;
  border-bottom: 1px solid #eee;
  border: none;
}

.panel-default > .panel-heading {
  padding: 0;
  border-radius: 0;
  color: #212121;
  background-color: transparent;
  border: 0px;
}

.panel-title > a {
  display: block;
  padding: 15px;
  text-decoration: none;
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: transparent;
}

.accordion .panel {
  background-color: transparent;
}

.accordion .panel:last-child {
  border-bottom: 0 none;
}

.accordion .panel + .panel {
  margin-top: 0;
}

.accordion .panel-title {
  position: relative;
  font-size: 1em;
}

.accordion .panel-title a {
  color: #333;
  padding: 11px 30px 11px 0;
}

.accordion .panel-title a .plus-minus {
  opacity: 0.4;
}

.accordion .panel-title a.collapsed {
  color: #666;
}

.accordion .panel-title a.collapsed:hover {
  opacity: 0.7;
}

.accordion .panel-title a.collapsed:hover .plus-minus {
  opacity: 0.4;
}

.accordion .panel-title a.collapsed .plus-minus {
  opacity: 0.7;
}

.accordion .panel-title a.collapsed .plus-minus span:before {
  transform: rotate(-180deg);
}

.accordion .panel-title a.collapsed .plus-minus span:after {
  transform: rotate(0deg);
}

.accordion .panel-title a:hover {
  opacity: 1;
}

.accordion .panel-title a:hover .plus-minus {
  opacity: 1;
}

.accordion .panel-body {
  padding: 5px 0 20px;
}

.accordion .plus-minus {
  opacity: 0.8;
  cursor: pointer;
  transition: opacity 350ms;
  display: block;
  position: absolute;
  right: 0;
  top: 50%;
  height: 18px;
  width: 18px;
  margin-top: -9px;
}

.accordion .plus-minus span {
  position: relative;
  height: 100%;
  display: block;
}

.accordion .plus-minus span:before,
.accordion .plus-minus span:after {
  content: "";
  position: absolute;
  background: #666;
  transition: transform 100ms;
}

.accordion .plus-minus span:before {
  height: 2px;
  width: 100%;
  left: 0;
  top: 50%;
  margin-top: -1px;
  transform: rotate(180deg);
}

.accordion .plus-minus span:after {
  height: 100%;
  width: 2px;
  top: 0;
  left: 50%;
  margin-left: -1px;
  transform: rotate(90deg);
}

.accordion .plus-minus:hover {
  opacity: 0.3;
}

.accordion.filled .panel:last-child {
  border-bottom: 0;
}

.accordion.filled .panel-title {
  background-color: #f5f5f5;
}

.accordion.filled .panel-title a {
  background: #ececec;
  padding-left: 20px;
  color: #009750;
}

.accordion.filled .panel-title a.collapsed {
  background-color: transparent;
  color: #747d88;
}

.accordion.filled .panel-title a.collapsed:hover {
  opacity: 0.7;
}

.accordion.filled .panel-body {
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
}

.accordion.filled .plus-minus {
  right: 20px;
}

.panel-group:last-child {
  margin-bottom: 0;
}

.text-slider .owl-nav .owl-prev,
.text-slider .owl-nav .owl-next {
  top: 130%;
}

/* @v110 -s */
.overflowvisible {
  overflow: visible !important;
}

.row-vm {
  display: flex;
  align-items: center;
}

.row-vm.reverses > div:last-child {
  order: 1;
}

.row-vm.reverses > div:first-child {
  order: 2;
}

.primary-bg {
  background: #1F65DB;
}

.secondary-bg {
  background: #ED355B;
}

@media only screen and (max-width: 991px) {
  .row-vm {
    display: block;
  }

  .pb-90 {
    padding-bottom: 40px;
  }

  .pt-90 {
    padding-top: 40px;
  }

  .pb-80 {
    padding-bottom: 30px;
  }

  .pt-80 {
    padding-bottom: 30px;
  }

  .pb-70 {
    padding-bottom: 25px;
  }

  .pt-70 {
    padding-bottom: 25px;
  }

  .pt-60 {
    padding-top: 30px;
  }

  .pb-60 {
    padding-bottom: 30px;
  }

  .pb-100 {
    padding-bottom: 50px;
  }

  .pt-100 {
    padding-top: 50px;
  }

  .pb-120 {
    padding-bottom: 60px;
  }

  .pt-120 {
    padding-top: 60px;
  }

  .ptb-120 {
    padding: 60px 0;
  }

  .ptm-30 {
    padding-top: 30px;
  }

  .pbm-30 {
    padding-bottom: 30px;
  }

  .ptm-20 {
    padding-top: 20px;
  }

  .pbm-20 {
    padding-bottom: 20px;
  }

  .ptm-10 {
    padding-top: 10px;
  }

  .pbm-10 {
    padding-bottom: 10px;
  }

  .ptm-0 {
    padding-top: 0;
  }

  .pbm-0 {
    padding-bottom: 0;
  }
}
/* @v110 -e */
@media only screen and (max-width: 767px) {
  .form-m-bttm {
    margin-bottom: 10px;
  }

  .section-head {
    padding: 60px 0 37px;
  }

  .button {
    padding: 0 25px;
  }

  .buttons {
    margin: 0 -5px;
  }

  .buttons li {
    margin: 0 5px 20px;
  }

  .pb-90 {
    padding-bottom: 40px;
  }

  .pt-90 {
    padding-top: 40px;
  }

  .pb-80 {
    padding-bottom: 30px;
  }

  .pt-80 {
    padding-bottom: 30px;
  }

  .pb-70 {
    padding-bottom: 25px;
  }

  .pt-70 {
    padding-bottom: 25px;
  }

  .pt-60 {
    padding-top: 30px;
  }

  .pb-60 {
    padding-bottom: 30px;
  }

  .pb-100 {
    padding-bottom: 50px;
  }

  .pt-100 {
    padding-top: 50px;
  }

  .pb-120 {
    padding-bottom: 60px;
  }

  .pt-120 {
    padding-top: 60px;
  }

  .ptb-120 {
    padding: 60px 0;
  }

  .ptm-30 {
    padding-top: 30px;
  }

  .pbm-30 {
    padding-bottom: 30px;
  }

  .ptm-20 {
    padding-top: 20px;
  }

  .pbm-20 {
    padding-bottom: 20px;
  }

  .ptm-10 {
    padding-top: 10px;
  }

  .pbm-10 {
    padding-bottom: 10px;
  }

  .ptm-0 {
    padding-top: 0;
  }

  .pbm-0 {
    padding-bottom: 0;
  }

  .box {
    max-width: 320px;
    margin: 0 auto 30px;
  }

  .tab-fix {
    max-width: 480px;
    margin: 0 auto;
  }

  .owl-nav .owl-prev .fa, .owl-nav .owl-next .fa {
    line-height: 1.3;
  }

  .owl-theme .owl-nav [class*=owl-] {
    margin: 5px 10px;
    height: 35px;
    width: 35px;
  }

  .text-slider .owl-nav .owl-prev,
.text-slider .owl-nav .owl-next {
    top: 125%;
  }

  .download-buttons {
    padding-top: 0;
    margin-top: 30px;
  }

  .download-buttons li {
    max-width: 250spx;
  }
}
@media only screen and (max-width: 375px) {
  .button {
    min-width: 130px;
  }
}
/* 05. Header Section */
.header-section {
  position: relative;
  height: calc(100vh + 80px);
  flex-shrink: 0;
}

.header-content {
  flex-grow: 1;
  overflow: hidden;
}

.header-texts {
  padding: 74px 0 35px;
  position: relative;
  z-index: 6;
}

.header-texts h1, .header-texts h2 {
  color: #fff;
  display: inline-block;
  margin-bottom: 20px;
}

.header-texts p {
  color: #fff;
  padding-bottom: 13px;
  font-weight: 300;
}

/* @v120 -s */
.theme-pro .header-texts h1,
.theme-pro .header-texts h2,
.theme-pro .header-texts p {
  color: #333;
}

/* @v120 -e */
.header-mockup {
  position: relative;
  top: 30px;
  z-index: 5;
}

.mockup-screen {
  position: absolute;
  max-width: 305px;
  top: 0;
}

.mockup-screen-one {
  position: relative;
}

.mockup-screen-one {
  z-index: 20;
}

.mockup-screen-two {
  z-index: 19;
  left: 105px;
}

.mockup-screen-three {
  z-index: 18;
  left: 205px;
}

.video-background {
  background: #000;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -99;
  overflow: hidden;
}

.video-background iframe {
  position: absolute;
  top: -10%;
  left: -10%;
  width: 120%;
  height: 120%;
  pointer-events: none;
}

.video-foreground {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.particles-container {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
}

.flex-box-middle {
  display: flex;
  align-items: center;
}

.half-header {
  height: auto;
}

.half-header .header-content {
  padding: 125px 0 85px;
}

.header-curbed, .header-fade {
  height: auto;
  padding-bottom: 50px;
}

.header-curbed.pb-0 {
  padding-bottom: 0;
}

.half-header.header-curbed {
  padding: 20px 0 120px;
  z-index: 2;
}

.half-header-about {
  margin-top: -100px;
}

.header-curbed:after {
  display: block;
  content: "";
  height: 100%;
  width: 100%;
  background: url(/images/landing/carb.png) bottom no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.header-fade:after, .header-fade:before {
  display: block;
  content: "";
  height: 100%;
  width: 100%;
  background-size: 100%;
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  z-index: 1;
}

.header-fade:after {
  background-image: url(/images/landing/fade.png);
  background-position: center bottom;
  background-repeat: no-repeat;
}

.header-fade:before {
  background-image: url(/images/landing/bg-fade.png);
  background-position: center bottom;
  background-repeat: no-repeat;
}

.light-header {
  min-height: initial !important;
  z-index: 1;
  height: 75px !important;
}

@media only screen and (min-width: 768px) {
  .light-header {
    height: 108px !important;
  }
}
.light-header:after {
  display: none;
}

@media only screen and (max-width: 767px) {
  .light-header .navbar-collapse {
    margin-top: 15px;
  }
}
.alt-header-mockup {
  display: inline-block;
  position: relative;
}

.alt-header-mockup img {
  border: 10px solid #fff;
  border-radius: 30px;
  box-shadow: 7px 29px 20px rgba(0, 0, 0, 0.08);
  z-index: 5;
}

.mockup-left {
  max-width: 170px;
  position: absolute;
  right: 100%;
  bottom: 0;
  margin-right: 20px;
}

.mockup-middle {
  position: relative;
  max-width: 220px;
}

.mockup-right {
  max-width: 195px;
  position: absolute;
  left: 100%;
  bottom: 0;
  margin-left: 20px;
}

.header-slider {
  z-index: 99;
}

.text-slider.header-texts {
  padding-bottom: 0;
}

.download-buttons.pb-60 {
  padding-bottom: 60px;
}

.header-texts .owl-theme .owl-nav [class*=owl-] {
  margin: 5px -70px;
}

/* @v110 -s */
.header-laptop-mockup {
  background: url("/images/landing/macbook.png") no-repeat top center;
  background-size: 100%;
  padding: 30px 120px 90px;
  position: relative;
  z-index: 9;
}

.header-laptop-screen {
  border-radius: 5px;
  max-width: 100%;
}

.half-header .header-laptop-mockup {
  min-width: 720px;
  padding: 30px 95px 50px;
  margin-top: 20px;
}

.header-flat {
  height: auto;
  overflow: visible;
  margin-bottom: 120px;
}

.header-flat:after {
  height: 120px;
  width: 100%;
  content: "";
  bottom: -120px;
  position: absolute;
  background: #fbfbfb;
}

.header-flat-s2 {
  position: relative;
}

.header-flat-s2:after {
  height: 120px;
  width: 100%;
  content: "";
  bottom: 0;
  position: absolute;
  background: #fff;
  z-index: 2;
}

.header-flat .header-laptop-mockup {
  margin-bottom: -90px;
}

.text-slider .buttons {
  margin-bottom: 30px;
}

.half-header.header-software .header-content {
  padding: 125px 0 70px;
}

/* @v110 -e */
@media only screen and (min-width: 1025px) and (max-width: 1366px) {
  .half-header .header-content h1 {
    font-size: 2.5em;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .header-texts h1 {
    font-size: 2.5em;
  }

  .header-section {
    height: 100vh;
  }

  .header-flat,
.half-header,
.header-curbed, .header-fade {
    height: auto;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header-section {
    height: 100vh;
  }

  .mockup-screen {
    max-width: 225px;
  }

  .header-texts {
    padding-right: 160px;
  }

  .header-mockup {
    position: absolute;
    width: 100%;
    top: 50%;
    right: -55%;
    transform: translate(0, -40%);
  }

  .has-carousel .header-texts {
    padding-left: 40px;
  }

  .header-flat,
.half-header,
.header-curbed, .header-fade {
    height: auto;
  }

  .header-flat .header-texts,
.header-curbed .header-texts,
.header-curbed-circle .header-texts, .header-fade .header-texts {
    padding-right: 0;
  }

  .header-text-slide {
    padding: 0 70px;
  }

  .header-texts .owl-theme .owl-nav [class*=owl-] {
    margin: 5px 15px;
  }

  .header-flat .header-laptop-mockup {
    margin-bottom: -60px;
  }
}
@media only screen and (max-width: 767px) {
  .header-section {
    height: 100vh;
    min-height: 300px;
  }

  .mockup-screen {
    max-width: 205px;
  }

  .header-mockup {
    position: absolute;
    width: 100%;
    top: 50%;
    right: -55%;
    transform: translate(0, -40%);
  }

  .has-carousel .header-texts {
    padding-left: 40px;
  }

  .header-texts {
    padding: 100px 0 20px 0;
    max-width: 360px;
    margin: 0 auto;
  }

  .header-curbed .header-texts, .header-fade .header-texts {
    padding: 55px 30px 20px;
    max-width: inherit;
  }

  .header-texts h1, .header-texts h2 {
    font-size: 1.6em;
  }

  .header-texts p {
    font-size: 1em;
  }

  .header-flat,
.half-header,
.header-curbed {
    height: auto;
  }

  .header-fade::before {
    display: none;
  }

  .half-header .header-content {
    padding: 20px 0 85px;
  }

  .alt-header-mockup img {
    border: 5px solid #fff;
    border-radius: 15px;
    box-shadow: 7px 10px 10px rgba(0, 0, 0, 0.08);
  }

  .mockup-middle {
    max-width: 110px;
  }

  .mockup-left {
    max-width: 90px;
    margin-right: 10px;
  }

  .mockup-right {
    max-width: 100px;
    margin-left: 10px;
  }

  .download-buttons.pb-60 {
    padding-bottom: 20px;
  }

  /* @v110 -s */
  .header-software .header-texts h1,
.header-software .header-texts h2 {
    font-size: 1.6em;
  }

  .header-laptop-mockup,
.half-header .header-laptop-mockup {
    padding: 16px 55px 32px;
    max-width: 440px;
    min-width: 440px;
    margin: 0 auto;
  }

  .half-header.header-curbed {
    padding: 20px 0 0;
  }

  .half-header.header-software .header-content {
    padding: 45px 0 25px;
  }

  .header-curbed.header-software {
    padding-bottom: 35px;
  }

  .half-header-about {
    margin-top: 0;
  }

  .header-flat .header-laptop-mockup {
    margin-bottom: -33px;
  }

  .header-flat {
    height: auto;
    overflow: visible;
    margin-bottom: 40px;
  }

  .header-flat:after {
    height: 40px;
    bottom: -40px;
  }

  /* @v110 -e */
  .header-section.header-fade {
    min-height: 600px;
  }
}
/* @v110 -s */
@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .header-laptop-mockup {
    padding: 25px 95px 60px;
    max-width: 720px;
    margin: 0 auto;
  }

  .header-curbed.header-software {
    padding-bottom: 20px;
  }
}
@media only screen and (max-width: 479px) {
  .header-laptop-mockup,
.half-header .header-laptop-mockup {
    padding: 14px 40px 25px;
    max-width: 280px;
    min-width: 280px;
    margin: 0 auto;
  }

  .header-curbed.header-software {
    padding-bottom: 15px;
  }

  .header-flat .header-laptop-mockup {
    margin-bottom: -25px;
  }
}
/* @v110 -e */
/* 06. Features Box Section */
.feature-boxes {
  margin-top: -80px;
  position: relative;
  z-index: 100;
}

@media only screen and (max-width: 1024px) {
  .feature-boxes {
    margin-top: 60px;
  }

  .feature-boxes .box {
    margin-top: 30px;
  }

  .half-header-box {
    margin-top: -110px;
  }
}
/* 07. Features Section */
.features-section {
  overflow: hidden;
  flex-shrink: 0;
}

.features-list {
  padding-top: 40px;
}

.single-features {
  position: relative;
  padding-left: 83px;
  margin-bottom: 45px;
}

.icon-right {
  padding-right: 83px;
  padding-left: 0;
}

.single-features .ti,
.single-features .fa {
  line-height: 60px;
  width: 60px;
  border-radius: 6px;
  text-align: center;
  font-size: 2.25em;
  color: #ff8400;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-block;
}

.icon-right .ti,
.icon-right .fa {
  left: auto;
  right: 0;
}

.single-features h4 {
  color: #fff;
  margin-bottom: 10px;
}

.single-features p {
  color: #fff;
  font-size: 0.99em;
  line-height: 1.6em;
}

/* @v120 -s */
.theme-pro .single-features h4,
.theme-pro .single-features p {
  color: #333;
}

/* @v120 -e */
.fearures-mockup {
  position: relative;
  top: 25px;
  margin-bottom: -40px;
}

.fearures-mockup {
  position: relative;
  top: 45px;
}

.fearures-mockup img {
  border-radius: 15px 15px 0 0;
  max-width: 260px;
}

.fearures-mockup.iphonex:after {
  position: absolute;
  content: url("/images/landing/iphonex.png");
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-49%, -50%) scale(0.6);
  transform: translate(-49%, -50%) scale(0.6);
}

.fearures-mockup.iphone:after {
  position: absolute;
  content: url("/images/landing/iphone.png");
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-49%, -48%) scale(0.82);
  transform: translate(-49%, -48%) scale(0.82);
}

.fearures-mockup-dubble {
  position: relative;
}

.fearures-mockup-alt {
  left: 20px;
}

.fearures-mockup-two {
  position: absolute;
  left: -40px;
  bottom: 0;
  top: auto;
  z-index: 9;
}

.fearures-mockup-two.iphone {
  bottom: -50px;
}

.fearures-mockup-dubble .fearures-mockup-two {
  margin-bottom: 0;
}

.fearures-mockup-two img {
  border-radius: 15px 15px 0 0;
  max-width: 200px;
}

.fearures-mockup-two.iphonex:after {
  -webkit-transform: translate(-49%, -50%) scale(0.45);
  transform: translate(-49%, -50%) scale(0.45);
}

.fearures-mockup-two.iphone:after {
  -webkit-transform: translate(-49.2%, -50%) scale(0.6);
  transform: translate(-49.2%, -50%) scale(0.6);
}

/* @v110 -s */
.fearures-software-mockup {
  min-width: 1100px;
  background: url("/images/landing/macbook.png") no-repeat top center;
  background-size: 100%;
  padding: 40px 140px 90px;
  float: right;
  margin-bottom: -60px;
  position: relative;
  z-index: 1;
}

.features-scrreen img {
  border: 1px solid #ececec;
  border-radius: 8px;
}

.browser-screen {
  position: relative;
}

.browser-expanded {
  min-width: 800px;
  float: right;
}

.browser-screen img {
  -moz-box-shadow: 0 0 29px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 29px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 29px rgba(0, 0, 0, 0.3);
}

.browser-screen:after {
  position: absolute;
  top: -30px;
  left: 0;
  height: 33px;
  width: 100%;
  content: "";
  background: url("/images/landing/browser-bar.png") bottom center no-repeat;
  background-size: 100%;
}

/* @v110 -e */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-features p {
    color: #fff;
    font-size: 0.8em;
  }

  .features-list {
    padding-top: 40px;
  }

  .single-features {
    margin-bottom: 30px;
  }

  /* @v110 -s */
  .fearures-software-mockup {
    margin-right: -80px;
  }

  /* @v110 -e */
}
@media only screen and (max-width: 991px) {
  .icon-right {
    padding-left: 0;
    padding-right: 0;
  }

  .single-features {
    text-align: center;
    padding-left: 0;
  }

  .single-features .ti {
    position: relative;
    margin-bottom: 20px;
  }

  .features-section .pull-right {
    float: none !important;
  }

  .features-list {
    max-width: 420px;
    margin: 0 auto;
    padding: 0;
  }

  .fearures-mockup-dubble {
    margin: 0 auto;
    margin-bottom: -110px;
    margin-top: -120px;
    left: 15px;
    max-width: 320px;
  }

  /* @v110 -s */
  .fearures-software-mockup {
    max-width: 720px;
    min-width: 720px;
    padding: 30px 95px 100px;
    float: none;
    margin: 0 auto -80px;
  }

  .browser-expanded {
    min-width: 100%;
    float: none;
  }

  /* @v110 -e */
}
@media only screen and (max-width: 767px) {
  .fearures-mockup-dubble {
    transform: scale(0.8);
    margin-bottom: -110px;
    margin-top: -120px;
    left: 15px;
    max-width: 320px;
  }

  /* @v110 -s */
  .fearures-software-mockup {
    max-width: 420px;
    min-width: 420px;
    padding: 20px 60px 70px;
    float: none;
    margin: 0 auto -60px;
  }

  .browser-expanded {
    min-width: 100%;
    float: none;
  }

  .header-fade .header-texts .button {
    font-size: 13px;
  }

  .header-fade .header-texts .download-buttons li {
    max-width: 200px;
  }

  /* @v110 -e */
}
/* @v110 -s */
@media only screen and (max-width: 479px) {
  .fearures-software-mockup {
    min-width: 300px;
    max-width: 300px;
    padding: 14px 40px 25px;
    float: none;
    margin: 0 auto -30px;
  }
}
/* @v110 -e */
/* 08. Screenshots Section */
.slide-screen {
  position: relative;
  margin-bottom: 100px !important;
}

.slide-screen .owl-item {
  opacity: 0.6;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.slide-screen .owl-item.center {
  opacity: 1;
}

/* .slide-screen.iphone::after {
	content: url("/public/images/iphone.png");
	left: 50%;
	position: absolute;
	top: 50%;
	-webkit-transform: translate(-50%, -50%) scale(0.6);
	transform: translate(-50%, -50%) scale(0.6);
	-webkit-transition:all .3s ease;
	-moz-transition:all .3s ease;
	transition:all .3s ease;
} */
.slide-screen.iphonex::after {
  content: url("/images/landing/iphonex.png");
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-49%, -51%) scale(0.45);
  transform: translate(-49%, -51%) scale(0.45);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.frame-fix.slide-screen:after {
  opacity: 0;
}

.owl-carousel .owl-item.center img {
  border-radius: 5px;
}

.iphonex.owl-carousel .owl-item img {
  width: 192px;
  margin: 0 auto;
  position: relative;
}

.iphone.owl-carousel .owl-item img {
  width: 192px;
  margin: 0 auto;
  position: relative;
  top: 40px;
}

.iphone.owl-carousel .owl-item {
  position: relative;
}

.iphone.owl-carousel .owl-item:after {
  position: absolute;
  content: "";
  height: 45px;
  width: 100%;
  background: #fff;
  bottom: 0;
  left: 0;
}

/* @v110 Start*/
.software-screens {
  margin-bottom: -40px;
}

.software-screens .item:after {
  position: absolute;
  top: -30px;
  left: 0;
  height: 33px;
  width: 100%;
  content: "";
  background: url("/images/landing/browser-bar.png") bottom center no-repeat;
  background-size: 100%;
}

.software-screens .item {
  margin: 30px;
  position: relative;
  -moz-box-shadow: 0 0 29px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0 0 29px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 29px rgba(0, 0, 0, 0.3);
}

.software-screens .owl-nav .owl-prev,
.software-screens .owl-nav .owl-next {
  font-size: 24px;
  background: transparent;
}

.software-screens .owl-nav .owl-prev {
  left: -60px;
}

.software-screens .owl-nav .owl-next {
  right: -60px;
}

.software-screens .owl-nav .owl-prev:hover {
  left: -70px;
  background: transparent;
}

.software-screens .owl-nav .owl-next:hover {
  right: -70px;
  background: transparent;
}

/* @v110 -e */
@media only screen and (max-width: 1169px) {
  .slide-screen {
    max-width: 700px;
    margin: 0 auto;
  }
}
/* 09. Statistics Section */
.statistics-section .box {
  margin-bottom: 30px;
}

/* 10. Pricing Section */
.pricing-section {
  position: relative;
  background-size: cover;
}

.pricing-box {
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
  box-shadow: 0 0 15px 0 rgba(2, 3, 3, 0.1);
}

.pricing-box:hover {
  transform: translateY(-8px);
}

.pricing-box {
  margin: 15px 0 30px;
  border-radius: 8px;
}

.pricing-top {
  border-radius: 8px 8px 0 0;
  padding: 30px 0;
}

.pricing-top h5 {
  color: #fff;
  margin-bottom: 12px;
}

.pricing-top h2 {
  color: #fff;
  font-weight: 100;
  font-size: 1.6em;
}

.pricing-badge {
  color: #fff;
  font-size: 0.9em;
  display: inline-block;
  margin-bottom: 15px;
  font-weight: 400;
  text-transform: uppercase;
}

.pricing-bottom {
  background: #fff;
  border-radius: 0 0 8px 8px;
  padding: 25px 20px 40px;
}

.pricing-bottom ul {
  margin-bottom: 25px;
}

.pricing-bottom ul li {
  font-weight: 400;
  line-height: 2.5;
  position: relative;
}

.pricing-bottom ul li .ti {
  margin-right: 10px;
  font-size: 0.8em;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 2.5;
  font-weight: bold;
}

.pricing-bottom .ti-close {
  color: #ff8400;
}

.pricing-bottom .ti-check {
  color: #00d578;
}

.pricing-box {
  background: #fff;
}

.pricing-top {
  position: relative;
}

.pricing-box-curbed .pricing-top:after {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  content: "";
  background: url("/images/landing/pricing-carb.png") no-repeat bottom;
  background-size: contain;
}

.pricing-box-curbed .pricing-top {
  padding: 50px 0;
}

@media only screen and (max-width: 991px) {
  .pricing-section.has-bg-image {
    background-color: #000;
  }

  .pricing-section.has-bg-image .imagebg {
    height: 400px;
    background-size: cover;
  }

  .pricing-section.has-bg-image .imagebg:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -20px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, black 80%, black 100%);
    height: 200px;
  }

  .pricing-box {
    max-width: 420px;
    margin: 0 auto 30px;
  }
}
/* 11. Faq Section */
.faqs {
  margin-bottom: 40px;
  position: relative;
}

.faqs:before {
  position: absolute;
  content: "";
  height: calc(100% + 35px);
  width: calc(100% + 25px);
  top: -20px;
  left: -20px;
  opacity: 0;
  background: rgba(0, 0, 0, 0.07);
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.faqs:hover:before {
  opacity: 1;
}

.faq-heading {
  margin-bottom: 10px;
}

.heading-light.side-heading a {
  color: #fff;
  border-bottom: 1px solid #fff;
}

.side-heading a {
  color: #333;
  border-bottom: 1px solid #333;
}

.faq-alt .faq-heading {
  color: #666;
}

.faq-heading {
  color: #fff;
}

.faqs p {
  color: #fff;
}

.faq-alt .faqs p {
  color: #333;
}

/* 12. Team Section */
.team-member {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;
  text-align: center;
}

.team-member .team-photo {
  position: relative;
}

.team-member .team-photo:after {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  opacity: 0.35;
  border-radius: 8px;
  content: "";
  transition: all 0.5s ease;
  /* @v110 */
  background-image: linear-gradient(225deg, rgba(255, 132, 0, 0), #ff8400), linear-gradient(45deg, rgba(247, 191, 19, 0), #f7bf13), linear-gradient(135deg, #f5c21b, #f7e5a9);
}

.team-member .team-photo img {
  margin: 0;
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.team-member .team-info {
  margin-top: 25px;
}

.team-member .team-info h4,
.team-member .team-info .name {
  margin-bottom: 5px;
}

.team-member .team-info .sub-title {
  font-size: 0.875em;
}

/* @v110 Start*/
.team-photo:hover:after {
  opacity: 0.7;
}

.team-photo img {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}

.expand-trigger {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 5;
  top: 0;
  left: 0;
  color: #fff;
  font-size: 14px;
}

.expand-trigger span {
  position: absolute;
  width: 100%;
  height: 50px;
  line-height: 50px;
  text-align: center;
  top: 50%;
  left: 0;
  text-transform: uppercase;
  font-weight: 500;
}

.expand-trigger:hover,
.expand-trigger:focus {
  color: #fff;
}

.expand-trigger .ti {
  position: absolute;
  font-size: 30px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  top: 50%;
  left: 50%;
  opacity: 0;
  transition: all 0.5s ease;
  transform: translate(-50%, -50%) scale(0);
}

.team-photo:hover .expand-trigger .ti {
  opacity: 1;
  transform: translate(-50%, -50%) scale(1);
}

.team-profile {
  max-width: 970px;
  margin: 30px auto;
  padding: 50px 35px;
  background: #fff;
  position: relative;
}

.team-profile-photo img {
  min-width: 100%;
}

.team-profile-info {
  padding: 30px 0 20px 20px;
}

.team-profile-info .name {
  margin-bottom: 5px;
}

.team-profile-info .sub-title {
  font-size: 0.875em;
  margin-bottom: 5px;
}

.team-profile-info .social li {
  display: inline-block;
}

.team-profile-info .social li a {
  font-size: 14px;
  margin-right: 10px;
}

.team-profile-info p {
  font-size: 0.875em;
  margin-bottom: 8px;
}

.team-profile-info p:last-of-type {
  margin-bottom: 28px;
}

.single-skill-bar {
  font-size: 12px;
  text-transform: capitalize;
  padding-bottom: 15px;
}

.skill-bar {
  position: relative;
  width: 100%;
  height: 4px;
  background: #ccc;
  margin-top: 5px;
}

.skill-bar-percent {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}

@media only screen and (max-width: 991px) {
  .team-profile-info {
    padding: 40px 0 0 0;
  }
}
/* @v110 -e */
/* 13. Testimonial Section*/
.testimonial-section {
  background-size: cover;
}

.testimonial-carousel.owl-carousel .owl-item img {
  display: block;
  width: auto;
}

.quotes {
  position: relative;
  padding: 20px 40px 65px;
  margin: 15px;
  margin-bottom: 40px;
  border-radius: 8px;
  background: #fff;
  -moz-box-shadow: 0 0 12px 0 rgba(2, 3, 3, 0.06);
  -webkit-box-shadow: 0 0 12px 0 rgba(2, 3, 3, 0.06);
  box-shadow: 0 0 12px 0 rgba(2, 3, 3, 0.06);
}

.quotes h6 {
  font-size: 16px;
}

.quotes blockquote {
  margin: 16px 0;
}

.quotes img {
  margin: 0 auto;
}

.quote-icon {
  max-width: 65px;
}

.client-image {
  height: 100px;
  width: 100px;
  position: absolute;
  left: 50%;
  margin-left: -50px;
  bottom: -40px;
}

.client-image:before {
  position: absolute;
  content: "";
  top: -12px;
  left: 40px;
  z-index: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 13px solid #ccc;
}

.client-image img {
  border-radius: 50%;
}

/* 14. Contact Section*/
.contact-form {
  border-radius: 8px;
  padding: 35px 35px 40px;
  margin-bottom: 30px;
}

.contact-form h3 {
  color: #ff8400;
  margin-bottom: 15px;
}

.contact-info {
  border-radius: 8px;
}

.contact-info h3 {
  color: #ff8400;
  margin-bottom: 15px;
}

.contact-info {
  padding: 30px 40px;
}

.contact-info h6 {
  font-size: 0.9em;
  font-weight: 400;
  margin: 10px 0;
}

.contact-info h6 .fa {
  width: 20px;
  color: #ff8400;
}

.contact-info a {
  color: inherit;
}

.google-map {
  height: 318px;
  border-radius: 0 0 8px 8px;
}

.form-note {
  color: #fff;
  font-size: 0.8em;
  display: block;
  margin-top: 15px;
}

.form-results {
  margin: 20px 0;
  font-size: 13px;
  text-align: center;
}

.form-results:last-child {
  margin-bottom: 0;
}

/* 16. Fix Media Query */
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tab-center {
    text-align: center;
  }

  .tab-left {
    text-align: left;
  }
}
@media only screen and (max-width: 767px) {
  .mobile-left {
    text-align: left;
  }

  .mobile-center {
    text-align: center;
  }

  body, .navigation {
    min-width: 300px;
  }
}
@media only screen and (max-width: 991px) {
  .navbar-nav .demo-dropdown .dropdown-menu {
    display: none !important;
  }
}
/* 17. Flat Page @v110 */
.steps-section .nav-tabs {
  border-bottom: none;
}

.steps-section .nav-tabs li {
  width: 100%;
  cursor: pointer;
}

.txt-feature h2 {
  font-size: 2em;
  margin-bottom: 15px;
  margin-top: 15px;
}

.txt-feature h3 {
  font-size: 1.5em;
}

.steps {
  display: block;
  width: 100%;
  padding: 25px 30px;
  transition: all 0.5s ease;
}

.steps h4 {
  margin-bottom: 10px;
}

.active .steps {
  cursor: default;
  -moz-box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.07);
  -webkit-box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.07);
  box-shadow: 0 0 11px 1px rgba(0, 0, 0, 0.07);
}

.active .steps h4 {
  color: #1F65DB;
}

.steps-screen {
  background: url("/images/landing/macbook.png") no-repeat top center;
  background-size: 100%;
  padding: 30px 90px 60px;
}

.logo-item {
  border-radius: 8px;
}

.contact-panel {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: flex-start;
  align-items: stretch;
  margin-left: 0;
  margin-right: 0;
}

.contact-panel [class*=col-] {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  float: none;
  padding: 25px;
}

.single-contact {
  color: #fff;
  padding: 60px 0;
}

.single-contact .fa {
  display: block;
  font-size: 36px;
  margin-bottom: 15px;
}

.scontact-info {
  font-size: 1.1em;
  line-height: 2;
}

.download-form input.form-control {
  border-radius: 25px;
  padding-left: 20px;
}

.download-form h2 {
  margin-bottom: 18px;
}

.download-form > .heading-light {
  margin-bottom: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .steps-screen {
    margin: 100px 0;
  }

  .row-vm .steps-screen {
    margin: 0;
  }
}
@media only screen and (max-width: 991px) {
  .steps-section .nav-tabs li {
    margin-bottom: 10px;
    background: #fff;
  }

  .steps-section .nav-tabs {
    margin-bottom: 30px;
  }

  .steps-screen {
    max-width: 720px;
    padding: 30px 95px 100px;
  }

  .txt-feature h2 {
    margin-top: 30px;
  }

  .scontact-info {
    font-size: 0.9em;
  }
}
@media only screen and (max-width: 767px) {
  .steps-screen {
    max-width: 420px;
    padding: 20px 60px 70px;
    margin: 0 auto;
  }

  .contact-panel [class*=col-] {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .scontact-info {
    font-size: 1.1em;
  }

  .single-contact {
    padding: 25px 0;
  }
}
@media only screen and (max-width: 479px) {
  .steps-screen {
    max-width: 300px;
    padding: 14px 40px 25px;
    margin: 0 auto;
  }

  .row-vm .steps-screen {
    margin-bottom: 20px;
  }

  .steps-section .nav-tabs {
    margin-bottom: 15px;
  }
}
/* @v120 -s */
.overflow-scroll {
  overflow: hidden !important;
  overflow-y: scroll !important;
}

.section-overflow-fix {
  position: relative;
}

.section-overflow-fix:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 130px;
  background: #fff;
  content: "";
}

.nav.inline-nav {
  border-bottom-color: transparent;
  margin-bottom: 40px;
}

.inline-nav .steps {
  padding: 12px 20px;
  border-radius: 25px;
}

.inline-nav .steps h4 {
  margin-bottom: 0;
}

.nav-tabs.inline-nav li {
  cursor: pointer;
  float: none;
  display: inline-block;
  margin: 0 15px;
}

.header-curbed-circle {
  height: auto;
  z-index: 2;
}

.header-curbed-circle:after {
  display: block;
  content: "";
  height: 100%;
  width: 100%;
  background: url("/images/landing/carb-circle.png") bottom no-repeat;
  background-size: contain;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.background-circles:before {
  display: block;
  content: "";
  height: 100%;
  width: 100%;
  background: url("/images/landing/bubble.png") top center no-repeat;
  background-size: cover;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
}

.theme-pro .background-circles:before {
  background-image: url("/images/landing/bubble-pro.png");
}

.theme-pro .has-bg-image.background-circles:before,
.theme-pro .has-bg-video.background-circles:before {
  background-image: url("/images/landing/bubble.png");
}

.header-laptop-mockup.black {
  background: url("/images/landing/macbook-black.png") no-repeat top center;
  background-size: 100%;
}

.header-mockups {
  position: relative;
  padding-bottom: 10px;
}

.header-mockups .iphonex-flat-mockup {
  position: absolute;
  bottom: 15px;
  right: 0;
  z-index: 99;
}

.half-header .header-mockups .iphonex-flat-mockup {
  bottom: -15px;
  right: -30px;
}

.iphonex-flat-mockup {
  position: relative;
  width: 190px;
  height: 390px;
}

.iphonex-flat-mockup.large {
  position: relative;
  width: 240px;
  height: 520px;
}

.iphonex-flat-mockup img {
  border-radius: 20px;
}

.iphonex-flat-mockup:after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: url("/images/landing/iphonex-flat.png");
  transform: translate(-51.5%, -49.3%) scale(0.5);
}

.iphonex-flat-mockup.large:after {
  transform: translate(-51.5%, -50.8%) scale(0.645);
}

.feature-box {
  margin-bottom: 60px;
}

.feature-box h4 {
  margin: 13px 0;
}

.fearures-software-mockup.black {
  background: url("/images/landing/macbook-black.png") no-repeat top center;
}

@media only screen and (max-width: 991px) {
  .fearures-software-mockup.black {
    background-size: 100%;
  }
}
.fearures-software-mockup.right {
  float: left;
}

.feature-mockups {
  position: relative;
}

.feature-mockups .phone-mockup {
  position: absolute;
  left: 20px;
  bottom: 25px;
  z-index: 1;
}

.steps-screen.black {
  background: url("/images/landing/macbook-black.png") no-repeat top center;
  background-size: 100%;
}

.laptop-mockup {
  background: url("/images/landing/macbook.png") no-repeat top center;
  background-size: 100%;
  padding: 30px 100px 90px;
  position: relative;
  z-index: 1;
}

.laptop-mockup.laptop-black {
  background: url("/images/landing/macbook-black.png") no-repeat top center;
  background-size: 100%;
}

.fade {
  transition: all 0.5s ease;
}

.tab-pane {
  position: relative;
}

.tab-pane .laptop-mockup {
  opacity: 0;
  transform: translate(0, 100px);
  transition: all 0.5s ease;
}

.tab-pane .iphonex-flat-mockup {
  opacity: 0;
  transform: translate(50px, 0);
  transition: all 0.5s ease 0.2s;
}

.fade.in .laptop-mockup,
.fade.in .iphonex-flat-mockup {
  opacity: 1;
  transform: translate(0);
}

.tab-pane .phone-mockup {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}

.feature-mockups .fearures-software-mockup {
  margin-bottom: 50px;
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .laptop-mockup {
    padding: 35px 95px 60px;
    max-width: 720px;
    margin: 0 auto;
  }

  .iphonex-flat-mockup {
    width: 150px;
    height: 310px;
  }

  .iphonex-flat-mockup.large {
    position: relative;
    width: 190px;
    height: 390px;
  }

  .iphonex-flat-mockup:after {
    transform: translate(-51.5%, -49.3%) scale(0.4);
  }

  .iphonex-flat-mockup.large:after {
    transform: translate(-51.5%, -49.3%) scale(0.5);
  }

  .inline-nav .steps {
    padding: 12px 20px;
    border-radius: 25px;
  }

  .inline-nav .steps h4 {
    font-size: 1em;
  }

  .nav-tabs.inline-nav li {
    margin: 0 10px;
  }
}
@media only screen and (max-width: 991px) {
  .inline-nav li, .inline-nav li:nth-child(2n) {
    background: transparent;
  }
}
@media only screen and (max-width: 767px) {
  .laptop-mockup {
    padding: 16px 55px 32px;
    max-width: 440px;
    min-width: 440px;
    margin: 0 auto;
  }

  .iphonex-flat-mockup,
.iphonex-flat-mockup.large {
    width: 100px;
    height: 204px;
  }

  .iphonex-flat-mockup:after,
.iphonex-flat-mockup.large:after {
    transform: translate(-50.8%, -49.3%) scale(0.265);
  }

  .feature-mockups,
.header-mockups,
.tab-pane {
    max-width: 450px;
    margin: 0 auto;
  }

  .feature-mockups .phone-mockup {
    bottom: -40px;
  }
}
@media only screen and (max-width: 479px) {
  .laptop-mockup {
    padding: 14px 40px 25px;
    max-width: 280px;
    min-width: 280px;
    margin: 0 auto;
  }

  .iphonex-flat-mockup.large,
.iphonex-flat-mockup {
    width: 70px;
    height: 142px;
  }

  .iphonex-flat-mockup img {
    border-radius: 10px;
  }

  .iphonex-flat-mockup.large:after,
.iphonex-flat-mockup:after {
    transform: translate(-50.6%, -49.6%) scale(0.185);
  }

  .feature-mockups,
.header-mockups,
.tab-pane {
    max-width: 280px;
    margin: 0 auto;
  }

  .header-mockups .iphonex-flat-mockup {
    bottom: -10px;
  }

  .nav-tabs.inline-nav li {
    display: block;
  }
}
/* @v120 -e */
.pt-0 {
  padding-top: 0;
}

.pb-170 {
  padding-bottom: 170px;
}

.bg-light {
  background: #f6fbff;
}

.meta-heading {
  margin-bottom: 5px;
}

.meta-heading + .heading, .heading-row {
  font-size: 2.075em;
}

.button.light {
  margin-top: 20px;
}

.feature-col {
  padding: 15px;
}

.feature-col .icon {
  font-size: 42px;
  padding-top: 10px;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  margin: 0 auto 30px auto;
}

.bg-circle {
  position: relative;
  z-index: -1;
}

.bg-circle .small, .bg-circle .big, .bg-circle .big:before,
.bg-circle .big:after {
  position: absolute;
  border-radius: 50%;
}

.bg-circle .small {
  height: 280px;
  width: 280px;
  top: 90px;
}

.bg-circle .big {
  height: 380px;
  width: 380px;
  right: 0;
  top: 0;
}

.bg-circle .big:before, .bg-circle .big:after {
  position: absolute;
  content: "";
}

.bg-circle .big:before {
  height: 28px;
  width: 28px;
  right: 60px;
  top: -40px;
}

.bg-circle .big:after {
  height: 45px;
  width: 45px;
  left: 0;
  bottom: -70px;
}

.pricing-box.alt h2 {
  color: inherit;
}

.pricing-box .pricing-bottom {
  background: transparent;
}

.pricing-box.gradiant-background .pricing-bottom {
  color: #fff;
}

.contact-form.trans {
  background: rgba(255, 255, 255, 0.2);
}

.contact-form.trans h2 {
  color: #fff;
}

.contact-form.trans .form-control {
  background: transparent;
  border-radius: 0;
  border-width: 0 0 1px 0;
  border-color: #fff;
}

.contact-form.trans .form-control::placeholder {
  color: #fff;
}

.fade-nav.navbar-right .demo-dropdown .dropdown-menu {
  left: calc(-660px + 38px );
}

@media only screen and (min-width: 991px) and (max-width: 1199px) {
  .fade-nav.navbar-right .demo-dropdown .dropdown-menu {
    left: calc(-455px + 38px );
  }
}
@media only screen and (max-width: 991px) {
  .features-content.center, .features-content .text-right {
    text-align: center;
  }

  .pb-170 {
    padding-bottom: 100px;
  }
}
@media only screen and (max-width: 767px) {
  .bg-circle .big {
    height: 300px;
    width: 300px;
  }

  .bg-circle .small {
    height: 200px;
    width: 200px;
  }

  .bg-circle .big::before {
    top: -20px;
  }

  .bg-circle .big::after {
    bottom: -20px;
  }
}
/* @v131 -s */
.overflow-hidden {
  overflow: hidden !important;
}

/* @v131 -s */
/* End */
.anchor-offset {
  padding-top: 50px;
  margin-top: -50px;
}

.text-indent {
  text-indent: 2em;
}

.section-privacy .section-head {
  padding-top: 40px;
  padding-bottom: 40px;
}
.section-privacy .section-privacy-text {
  text-align: justify;
}
.section-privacy h2 {
  margin-bottom: 10px;
}
.section-privacy h2 + p {
  font-size: 0.85em;
}
.section-privacy h3 {
  margin-top: 44px;
  margin-bottom: 22px;
}
.section-privacy h4 {
  margin-bottom: 18px;
}
.section-privacy p {
  text-indent: 2em;
}
.section-privacy th {
  text-indent: 0;
  text-align: left;
}
.section-privacy tbody tr:nth-child(even) {
  background: rgba(0, 0, 0, 0.04);
}
.section-privacy th, .section-privacy td {
  padding: 14px 12px;
}
.section-privacy td {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.h1-tag {
  display: none !important;
}